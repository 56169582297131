import '@/styles.css'

const minDistance = 100
const overallDistance = 200000

const appEl = document.getElementById('app')!
const targetEl = document.getElementById('target')!
const startEl = document.getElementById('start')!
const startButtonEl = document.getElementById('startButton')!
const statsEl = document.getElementById('stats')!
const counterEl = document.getElementById('counter')!
const maxEl = document.getElementById('max')!
const maxSelectEl = document.getElementById('maxSelect')! as HTMLSelectElement

type Action = {
  x: number
  y: number
  distance: number
  speed: number
  time: number
  click: boolean
  success: boolean
}

type Position = {
  x: number
  y: number
  distance: number
}

let actions: Action[] = []
let positions: Position[] = []
let positionIndex = 0
let counter = 0
let lastX = 0
let lastY = 0
let lastTime = Date.now()

let couldRestart = true
let isRunning = false

const canvas = document.createElement('canvas')
canvas.width = appEl.clientWidth
canvas.height = appEl.clientHeight
canvas.style.position = 'absolute'
canvas.style.top = '0'
canvas.style.left = '0'
canvas.style.display = 'none'
appEl.appendChild(canvas)

let startTime = 0

startButtonEl.addEventListener('click', (event) => {
  start(event)
  startTime = Date.now()
})

appEl.addEventListener('click', (event) => {
  event.preventDefault()
  if (!isRunning) {
    if (couldRestart) {
      reset()
    }
    return
  }
  record(event, true, false)
})

targetEl.addEventListener('click', (event) => {
  event.stopImmediatePropagation()
  if (!isRunning) {
    return
  }
  record(event, true)
  counter--
  counterEl.innerHTML = `${counter}`

  setNewTargetPosition()
})

appEl.addEventListener('mousemove', (event) => {
  if (!isRunning) {
    return
  }

  record(event)
})

function record(event: MouseEvent, click = false, success = true) {
  const now = Date.now()

  const x = event.clientX - appEl.offsetLeft
  const y = event.clientY - appEl.offsetTop
  const time = now - lastTime
  const distance = Math.sqrt(Math.pow(x - lastX, 2) + Math.pow(y - lastY, 2))
  const speed = distance / time
  actions.push({ x, y, distance, speed, time, click, success })

  lastX = x
  lastY = y
  lastTime = now
}

function setNewTargetPosition() {
  if (positionIndex >= positions.length) {
    finish()
  } else {
    targetEl.style.left = `${positions[positionIndex].x}px`
    targetEl.style.top = `${positions[positionIndex].y}px`
    positionIndex++
  }
}

function start(event: MouseEvent) {
  const center = { x: appEl.clientWidth / 2, y: appEl.clientHeight / 2 }
  let remainingDistance = overallDistance
  positions = []
  positionIndex = 0

  const maxTargets = parseInt(maxSelectEl.value)
  counter = maxTargets
  counterEl.innerHTML = `${counter}`

  let prevX = center.x
  let prevY = center.y

  let retries = 0

  while (positions.length < maxTargets && remainingDistance > 0 && retries < 1000) {
    const angle = Math.random() * 2 * Math.PI
    const randomDistanceX = Math.random() * appEl.clientWidth
    const randomDistanceY = Math.random() * appEl.clientHeight

    let x = center.x + randomDistanceX * Math.cos(angle)
    let y = center.y + randomDistanceY * Math.sin(angle)

    // Grenzen anpassen: Stelle sicher, dass die Position innerhalb der erlaubten Bereiche bleibt
    const minX = 0 + targetEl.clientWidth
    const minY = 0 + targetEl.clientHeight
    const maxX = appEl.clientWidth - 100
    const maxY = appEl.clientHeight - 100

    // Setze sicher, dass x und y innerhalb der Grenzen bleiben
    x = Math.max(minX, Math.min(x, maxX))
    y = Math.max(minY, Math.min(y, maxY))

    // Prüfen, ob die neue Position mindestens minDistance von allen anderen Punkten entfernt ist
    let valid = true
    const dist = Math.sqrt(Math.pow(prevX - (x + 50), 2) + Math.pow(prevY - (y + 50), 2))
    if (remainingDistance >= minDistance) {
      if (dist < minDistance) {
        valid = false
      }
    }

    // Wenn die Position gültig ist, hinzufügen und den Abstand aktualisieren
    if (valid) {
      positions.push({ x, y, distance: dist })
      remainingDistance -= dist

      prevX = x + 50
      prevY = y + 50
    }
    retries++
  }
  if (retries >= 1000) {
    alert('Fehler: Ziel-Platzierung kann nicht ermittelt werden')
    return
  }

  startEl.classList.add('hidden')
  targetEl.classList.remove('hidden')
  statsEl.classList.add('hidden')
  counterEl.classList.remove('hidden')
  maxEl.classList.add('hidden')

  isRunning = true

  actions = []

  lastX = event.clientX - appEl.offsetLeft
  lastY = event.clientY - appEl.offsetTop
  lastTime = Date.now()

  setNewTargetPosition()
}

function reset() {
  startEl.classList.remove('hidden')
  statsEl.classList.add('hidden')
  maxEl.classList.remove('hidden')

  // clear canvas
  const ctx = canvas.getContext('2d')!
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  canvas.style.display = 'none'
}

function finish() {
  isRunning = false
  couldRestart = false

  targetEl.classList.add('hidden')
  statsEl.classList.remove('hidden')
  counterEl.classList.add('hidden')

  setTimeout(() => {
    couldRestart = true
  }, 1000)

  // draw movement
  canvas.style.display = 'block'
  const ctx = canvas.getContext('2d')!
  // ctx.strokeStyle = 'red'
  ctx.lineWidth = 2

  const distances = []
  const times = []
  let clickDistance = 0
  let clickTimes = 0
  for (let i = 2; i < actions.length; i++) {
    clickDistance += actions[i].distance
    clickTimes += actions[i].time
    if (actions[i].click) {
      distances.push(clickDistance)
      times.push(clickTimes)
      clickDistance = 0
    }
  }

  let clickIndex = 0
  let moveDistance = distances[0]
  let moveTime = times[0]
  let colorValue = 255

  let lastX = actions[0].x
  let lastY = actions[0].y

  for (let i = 1; i < actions.length; i++) {
    ctx.beginPath()
    ctx.moveTo(lastX, lastY)

    if (actions[i].click) {
      moveDistance = distances[clickIndex]
      moveTime = times[clickIndex]

      // colorValue = moveDistance
      colorValue = moveTime
      clickIndex++
    }

    moveDistance -= actions[i].distance
    moveTime -= actions[i].time

    // let color = Math.round(((colorValue - moveDistance) / colorValue) * 255)
    let color = Math.round(((colorValue - moveTime) / colorValue) * 255)
    if (isNaN(color)) {
      color = 255
    }

    ctx.strokeStyle = `rgb(${255 - color}, ${255 - color}, ${255 - color})`
    ctx.lineTo(actions[i].x, actions[i].y)
    ctx.stroke()
    ctx.closePath()

    lastX = actions[i].x
    lastY = actions[i].y
  }

  // draw click points
  ctx.fillStyle = 'blue'
  let clickCounter = 0
  let milliseconds = 0
  let index = 0
  let movement = 0
  let distance = 0

  // draw positions
  for (let pos of positions) {
    ctx.beginPath()
    ctx.setLineDash([5, 5])
    ctx.strokeStyle = 'white'
    ctx.arc(pos.x + 50, pos.y + 50, 50, 0, 2 * Math.PI)
    ctx.stroke()
    ctx.closePath

    distance += pos.distance
  }

  clickIndex = 0
  let points = ''
  let prevMovement = 0

  for (let action of actions) {
    milliseconds += action.time
    movement += action.distance

    if (action.click) {
      points += `${clickIndex + 1}: ${Math.round(movement - prevMovement)}px, ${milliseconds}ms<br>`
      prevMovement = movement

      ctx.beginPath()
      if (action.success) {
        ctx.fillStyle = 'blue'
      } else {
        ctx.fillStyle = 'red'
      }
      ctx.arc(action.x, action.y, 5, 0, 2 * Math.PI)
      ctx.fill()
      ctx.closePath()

      // write index into circle
      clickCounter++
      ctx.fillStyle = 'white'
      ctx.font = '10px Arial'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(clickCounter.toString(), action.x, action.y)

      // write time
      ctx.font = '10px Arial'
      ctx.fillText(`${milliseconds}ms`, action.x, action.y + 12)

      milliseconds = 0
      clickIndex++
    }
    index++
  }

  const time = Date.now() - startTime

  // rounded to 2 decimal places
  const maxTargets = parseInt(maxSelectEl.value)
  const timePerClick = Math.round((time / maxTargets) * 100) / 100

  const percentMovement = Math.round((movement * 100) / distance) - 100

  statsEl.innerHTML =
    `<div class="flex flex-row">` +
    `<div>` +
    `Distanz: ${Math.round(distance)}px<br>` +
    `Bewegung: ${Math.round(movement)}px (${percentMovement}%)<br>` +
    `Gesamtzeit: ${time}ms<br>` +
    `Je Klick: ${timePerClick}ms<br>` +
    `</div>` +
    `<div class="ml-4 text-sm">` +
    points +
    `</div>`
}
